import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { Spinner, Modal, Form } from "react-bootstrap";
import Barcode from "react-barcode";
import { useSnackbar } from "notistack";
import Select from "react-select";
import axios from "axios";
import Papa from "papaparse";
import BarcodeScanner from "../barcodeFunctionality/Scanner";
import Constant from "../../shared/_helpers/constants";
import BackArrow from "../../assets/images/back-arrow.svg";
import BarcodeScan from "../../assets/images/barcode.svg";
import SearchIcon from "../../assets/images/Search.svg";
import scanIcon from "../../assets/images/scan-orange.svg";
import ImportIcon from "../../assets/images/import-item.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import DataTable from "react-data-table-component";
import UserContext from "../../context/userContext";

const AddBarcodes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const account = useContext(UserContext);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [matrials, setMaterials] = useState([]);
  const [tempMatrials, setTempMaterials] = useState({});
  const [userCompany, setUserCompany] = useState([]);
  const [userCompanyMaterials, setUserCompanyMaterials] = useState([]);

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const fileInputRef = useRef(null);

  const [showModalBarcode, setShowModalBarcode] = useState(false);
  const [showModalScan, setShowModalScan] = useState(false);

  const [barcodeValue, setBarcodeValue] = useState([]);
  const [scanItemCodeValue, setScanItemCodeValue] = useState({
    ItemCode: "",
    barcodeValue: "",
    ItemDescription: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteBarcodeID, setDeleteBarcodeID] = useState("");

  const [showQRScanner, setShowQRScanner] = useState(false);

  const onDetected = (result) => {
    setScanItemCodeValue({
      ...scanItemCodeValue,
      barcodeValue: result.codeResult.code,
    });
    setShowQRScanner(false);
  };

  const CanoID = account.CanoID;
  const Email = account.email.replaceAll("+", "%2B");

  const getUsers = async () => {
    try {
      const [UsersCompany, warehouseList] = await Promise.all([
        axios.get(
          `${Constant.BASE_URL}/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}&Email=${Email}`
        ),
        axios.get(
          `${Constant.BASE_URL}/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}`
        ),
      ]);
      const UserCompArry = UsersCompany.data.data.map(
        (user) => user.Company_Code
      );
      setUserCompany(UserCompArry);
      let AllMaterialList = [];

      const fetchPromises = UserCompArry.map((cc) => {
        return axios.get(
          `${Constant.BASE_URL}/api/APIv1GetWarehouseItemsByCompany?CanoID=${CanoID}&CompanyCode=${cc}`
        );
      });

      const responses = await Promise.all(fetchPromises);
      for (let i = 0; i < UserCompArry.length; i++) {
        const data = responses[i].data;
        if (data.status) {
          AllMaterialList.push(...data.data);
        }
      }
      const warehouses = warehouseList.data.data.reduce((acc, item) => {
        acc[item["Warehouse_Code"].toString()] = item["Name"].trim();
        return acc;
      }, {});

      for (let i in AllMaterialList) {
        setTempMaterials((prevTempMaterials) => ({
          ...prevTempMaterials,
          [AllMaterialList[i].Item_Code]: AllMaterialList[i],
        }));
        AllMaterialList[i]["Warehouse_Name"] =
          warehouses[AllMaterialList[i]["Warehouse_Code"]];
      }

      setMaterials(AllMaterialList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching matrials:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line

  const changeCompanySelection = async (e) => {
    setUserCompanyMaterials([]);
    setSelectedCompany(e.target.value);
    const companyMaterials = await axios.get(
      `${Constant.BASE_URL}/api/APIv1GetWarehouseItemsByCompany?CanoID=${CanoID}&CompanyCode=${e.target.value}`
    );
    if (companyMaterials.data.data.length === undefined) {
      return enqueueSnackbar(`Selected company does't have any barcode`, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setUserCompanyMaterials(companyMaterials?.data?.data);
  };

  const handleConfirmScan = async (e) => {
    try {
      if (scanItemCodeValue.barcodeValue === "") {
        return enqueueSnackbar(`Please enter valid a Barcode`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } else if (scanItemCodeValue.ItemCode === "") {
        return enqueueSnackbar(`Please select a Item Code`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setShowModalScan(false);

      if (tempMatrials.hasOwnProperty(scanItemCodeValue.ItemCode)) {
        const itemData = tempMatrials[scanItemCodeValue.ItemCode];
        const isBarcodeExist = itemData?.Barcode?.some(
          (barcode) => barcode.barcode === scanItemCodeValue.barcodeValue
        );

        if (isBarcodeExist) {
          enqueueSnackbar(`Barcode value already exists`, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          const saveData = await axios.post(
            `${Constant.BASE_URL}/api/APIv1SaveBarcode`,
            {
              Id: new Date().getTime().toString(),
              CompanyCode: selectedCompany,
              ItemCode: scanItemCodeValue?.ItemCode,
              ItemDescription: itemData?.Item_Description,
              Barcode: scanItemCodeValue?.barcodeValue.toString(),
            }
          );
          console.log(saveData);
          if (saveData.status === 200) {
            enqueueSnackbar(`Barcode added successfully`, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(saveData.data.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
          setLoading(true);
          getUsers();
        }
      } else {
        return enqueueSnackbar(`Item code is not valid`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${error}`, { variant: "error", autoHideDuration: 3000 });
    }
    setScanItemCodeValue({});
  };

  // Show & Hide barcode modal
  const showBarcodeByCode = (itemCode) => {
    const uniqueBarcodes = new Set();
    const uniqueBarcodeObjects = itemCode.filter((item) => {
      if (!uniqueBarcodes.has(item.barcode)) {
        uniqueBarcodes.add(item.barcode);
        return true;
      }
      return false;
    });
    setBarcodeValue(uniqueBarcodeObjects);
    setShowModalBarcode(true);
  };

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9/ -]*$/; // Allow letters, numbers, hyphen, forward slash, and space
    if (!pattern.test(search)) {
      return setFilteredData([]);
    }

    const searchWords = search.toLowerCase().split(/\s+/); // Split search by whitespace
    const resultData = matrials.filter((material) => {
      return searchWords.every((word) =>
        material?.Item_Description?.toLowerCase().includes(word)
      );
    });

    setFilteredData(resultData);
  }, [search, matrials]);

  const removeItemBarcodeModal = (barcodeID) => {
    setShowModalBarcode(false);
    setShowDeleteModal(true);
    setDeleteBarcodeID(barcodeID);
  };

  const handleConfirmDeleteBarcode = () => {
    setShowDeleteModal(false);
    axios
      .get(`${Constant.BASE_URL}/api/APIv1DeleteBarcode?ID=${deleteBarcodeID}`)
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar(`Barcode removed successfully`, {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        setLoading(true);
        getUsers();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`${error}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  const processCsvData = async (data) => {
    if (tempMatrials.hasOwnProperty(data?.ItemCode)) {
      const isBarcodeExist = tempMatrials[data?.ItemCode].Barcode.some(
        (list) => list.barcode === data?.BarcodeValue
      );
      if (isBarcodeExist) {
        console.log(data?.BarcodeValue + "- Barcode already exists");
      } else {
        const saveData = await axios.post(
          `${Constant.BASE_URL}/api/APIv1SaveBarcode`,
          {
            Id: new Date().getTime().toString(),
            CompanyCode: data?.CompanyCode,
            ItemCode: data?.ItemCode,
            ItemDescription: tempMatrials[data?.ItemCode].Item_Description,
            Barcode: data?.BarcodeValue.toString(),
          }
        );
        if (saveData.data.status === "success") {
          enqueueSnackbar(`Barcode added successfully`, {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(saveData.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      }
    } else {
      console.log(data?.ItemCode + "- Item code not found in list");
      enqueueSnackbar(data?.ItemCode + "- Item code not found in list", {
        variant: "error",
      });
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files;
    if (file) {
      Papa.parse(file[0], {
        header: true,
        complete: async function (results) {
          console.log(results.data);
          if (
            results.data[0]?.hasOwnProperty("ItemCode") &&
            results.data[0]?.hasOwnProperty("BarcodeValue")
          ) {
            for (const data of results.data.slice(0, -1)) {
              await processCsvData(data);
            }
            enqueueSnackbar("Barcodes updated successfully", {
              variant: "success",
            });
            getUsers();
          } else {
            enqueueSnackbar("CSV file invalid", { variant: "error" });
          }
        },
      });
    }
  };

  const columns = [
    {
      name: "Material Name",
      minWidth: "300px",
      selector: (row) => row?.Item_Description,
      sortable: true,
    },
    {
      name: "Company Code",
      minWidth: "200px",
      selector: (row) => row?.Company_Code,
      sortable: true,
    },
    {
      name: "Item Code",
      minWidth: "150px",
      selector: (row) => row?.Item_Code,
      sortable: true,
    },
    {
      name: "Warehouse",
      minWidth: "300px",
      selector: (row) => row?.Warehouse_Name,
      sortable: true,
    },
    {
      name: "U/M",
      minWidth: "100px",
      selector: (row) => row?.UOM,
      sortable: true,
    },
    {
      name: "Barcode",
      width: "150px",
      cell: (row) => (
        <div className="" onClick={(e) => showBarcodeByCode(row?.Barcode)}>
          <img
            src={scanIcon}
            alt="Barcode"
            className="mx-3"
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid inventory-count">
      <div className="row">
        <div className="col-lg-12">
          <div className="page-heading d-flex">
            <Link to="/">
              <img src={BackArrow} alt="arrow" />
            </Link>
            <h4>Barcodes </h4>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : matrials}
        pagination
        subHeader
        subHeaderComponent={
          <div className="row search_head w-100 d-flex align-items-center justify-content-between">
            <div className="col-lg-5 col-12 mb-2">
              <img
                src={SearchIcon}
                className="search-icon"
                alt="search"
                style={{ marginTop: "30px" }}
              />
              <input
                type="text"
                placeholder="Search by material name"
                className="form-control form-control-lg"
                style={{ borderRadius: "40px", paddingLeft: "40px" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-lg-7 col-12 mb-2">
              <div className="d-flex btn-custom scan-item">
                <button
                  className="import-it me-3"
                  onClick={() => fileInputRef.current.click()}
                >
                  <img src={ImportIcon} className="mr-2" alt="importIcon" />{" "}
                  Import List
                </button>
                <input
                  type="file"
                  accept=".csv"
                  className="d-none"
                  id="csvFileInput"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />

                <button onClick={(e) => setShowModalScan(true)}>
                  <img src={BarcodeScan} alt="barcode" /> Scan Item
                </button>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />

      <Modal
        show={showModalBarcode}
        onHide={(e) => setShowModalBarcode(false)}
        centered
      >
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Item Barcodes
          </h6>
          <button
            type="button"
            className="close btn"
            onClick={(e) => setShowModalBarcode(false)}
            aria-label="Close"
          >
            <span aria-hidden="true" className="fs-4">
              &times;
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {barcodeValue.length > 0 ? (
              barcodeValue.map((key) => {
                return (
                  <div className="col-12" key={key.id}>
                    <div
                      className="border my-2 d-flex justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <Barcode value={key.barcode} className="d-block" />
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        style={{
                          width: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "0",
                          right: "0",
                        }}
                        onClick={(e) => removeItemBarcodeModal(key.id)}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <h6>No barcodes found for this item</h6>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel_btn"
            onClick={(e) => setShowModalBarcode(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalScan} onHide={(e) => setShowModalScan(false)}>
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Add New Barcode
          </h6>
        </Modal.Header>
        <Modal.Body>
          <div className="btn-custom-copy d-flex justify-content-center mb-2">
            <button onClick={(e) => setShowQRScanner(!showQRScanner)}>
              <img src={BarcodeScan} alt="barcode" className="me-2" />
              {showQRScanner ? "Stop Scan" : "Scan Item"}
            </button>
          </div>

          <div className="d-flex justify-content-center">
            {showQRScanner ? (
              <BarcodeScanner
                onDetected={onDetected}
                isModalOpen={showQRScanner}
              />
            ) : (
              ""
            )}
          </div>

          <Form.Group
            className="mb-3 text-center"
            controlId="exampleForm.ControlInput0"
          >
            <Form.Label style={{ fontSize: "14px" }}>Or</Form.Label>
            <select
              className="selectpicker form-control"
              value={selectedCompany}
              onChange={(e) => changeCompanySelection(e)}
            >
              <option value="">Select Company</option>
              {userCompany?.map((comp) => (
                <option key={comp} value={comp}>
                  {comp}
                </option>
              ))}
            </select>
          </Form.Group>

          {/* <Form.Group
            className="mb-3 text-center"
            controlId="exampleForm.ControlInput2"
          >
            <select
              className="selectpicker form-control"
              name="destinationWarehouse"
              value={scanItemCodeValue?.ItemCode}
              onChange={(e) =>
                setScanItemCodeValue({
                  ...scanItemCodeValue,
                  ItemCode: e.target.value,
                })
              }
            >
              <option value="">Select Item Code</option>
              {userCompanyMaterials?.map((mat, index) => (
                <option key={mat.Item_Code + index} value={mat.Item_Code}>
                  {mat.Item_Code + " - " + mat.Item_Description}
                </option>
              ))}
            </select>
          </Form.Group> */}

          <Select
            className="selectpicker mb-3 text-left"
            name="destinationWarehouse"
            placeholder="Search Item Code..."
            onChange={(selectedOption) =>
              setScanItemCodeValue({
                ...scanItemCodeValue,
                ItemCode: selectedOption.value,
                ItemDescription: selectedOption.label.split(" - ")[1],
                label: selectedOption.label,
              })
            }
            options={userCompanyMaterials?.map((mat, index) => ({
              value: mat.Item_Code,
              label: mat.Item_Code + " - " + mat.Item_Description,
            }))}
          />

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              disabled={showQRScanner}
              placeholder="Enter Barcode Value"
              value={scanItemCodeValue?.barcodeValue}
              onChange={(e) =>
                setScanItemCodeValue({
                  ...scanItemCodeValue,
                  barcodeValue: e.target.value,
                })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel_btn"
            onClick={(e) => setShowModalScan(false)}
          >
            Cancel
          </button>
          <button className="conform_btn" onClick={handleConfirmScan}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={(e) => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Item Barcodes
          </h6>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this barcode?</Modal.Body>
        <Modal.Footer>
          <button
            className="cancel_btn"
            onClick={(e) => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button className="conform_btn" onClick={handleConfirmDeleteBarcode}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddBarcodes;
